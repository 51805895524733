<template>
  <b-container>
    <b-card>
      <p>
        {{ item.transactionDate | date }}
      </p>

      <b-row class="mb-2">
        <b-col
          class="d-flex align-items-center"
          md="5"
        >
          <p class="price">
            {{ item.transactionValue | value }}
          </p>
        </b-col>

        <b-col
          md="5"
        >
          <div class="d-flex flex-column">
            <b-img
              class="ml-3"
              width="50"
              :src="brandSrc"
            />

            <p
              v-if="item.brandDescription === 'MASTERCARD'"
              class="ml-2"
            >
              {{ item.brandDescription }}
            </p>
          </div>

        </b-col>

        <b-col md="2" />

      </b-row>

      <b-row>
        <b-col
          md="5"
        >
          <h6> Status: </h6>
          <p> {{ item.status }} </p>
        </b-col>
        <b-col
          md="5"
        >
          <h6> Titular do Cartão: </h6>
          <p> {{ item.cardHolderName || '-----' }} </p>
        </b-col>

        <b-col
          md="2"
        >
          <h6> Valor da Transação: </h6>
          <p> {{ item.transactionValue | value }} </p>
        </b-col>

        <b-col md="5">
          <h6> Data da Solicitação: </h6>
          <p> {{ item.incomingDate | date }} </p>
        </b-col>
        <b-col
          md="5"
        >
          <h6> Data da Transação: </h6>
          <p> {{ item.transactionDate | date }} </p>
        </b-col>

        <b-col
          md="2"
        >
          <h6> Número do Processo: </h6>
          <p> {{ item.processNumber }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> Método de Pagamento: </h6>
          <p> {{ item.paymentMethodDescription }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> Razão: </h6>
          <p> {{ item.chargebackReason }} </p>
        </b-col>

        <b-col
          md="2"
        >
          <h6> Fraude: </h6>
          <p> {{ item.isFraud ? 'Sim' : 'Não' }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> Status do Último Documento: </h6>
          <p> {{ item.lastDocumentStatus || '-----' }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> Quantidade de parcelas: </h6>
          <p> {{ item.installmentQuantity }} </p>
        </b-col>

        <b-col
          md="2"
        >
          <h6> Parcela de número: </h6>
          <p> {{ item.installmentNumber }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> Solicitação de Evidência: </h6>
          <p> {{ item.evidenceRequest || '-----' }} </p>
        </b-col>

        <b-col
          md="5"
        >
          <h6> NSU: </h6>
          <p> {{ item.nsu }} </p>
        </b-col>
      </b-row>
    </b-card>

    <b-card>
      <b-row>
        <b-col md="3">
          <b-button
            style="width: 100%"
            variant="primary"
            @click="$bvModal.show('chargeback-modal')"
          >
            Download
          </b-button>
        </b-col>

        <b-col md="3">
          <b-button
            style="width: 100%"
            variant="primary"
            @click="$bvModal.show('chargeback-upload')"
          >
            Upload
          </b-button>
        </b-col>
      </b-row>

      <b-modal
        id="chargeback-modal"
        hide-footer
        size="lg"
      >
        <b-list-group>
          <b-list-group-item
            v-for="(file, index) in availableFiles"
            :key="index"
            @click="downloadFile(file.fileName)"
          >
            <b-row>
              <b-col md="9">
                {{ file.fileName }}
              </b-col>
            </b-row>
          </b-list-group-item>
        </b-list-group>
      </b-modal>

      <b-modal
        id="chargeback-upload"
        hide-footer
        size="lg"
      >
        <b-form>
          <b-form-group>
            <b-form-file v-model="file" />
          </b-form-group>
        </b-form>

        <b-button @click="uploadFile(file)">
          Enviar
        </b-button>
      </b-modal>
    </b-card>
  </b-container>
</template>

<script>
import {
    BContainer, BCard, BRow, BCol, BImg, BButton, BModal, BListGroup, BListGroupItem, BForm, BFormGroup, BFormFile,
} from 'bootstrap-vue';

import { FeatherIcon } from 'vue-feather-icons';
import ChargebackService from '@/service/chargeback';

const paymentSchemesToImg = {
    'AMERICAN EXPRESS': 'amex.svg',
    MASTERCARD: 'mastercard.svg',
    VISA: 'visa.svg',
    ELO: 'elo.svg',
    HIPERCARD: 'hipercard.svg',
};

export default {
    components: {
        BModal,
        BContainer,
        BCard,
        BRow,
        BCol,
        BImg,
        BButton,
        BListGroup,
        BListGroupItem,
        FeatherIcon,
        BForm,
        BFormGroup,
        BFormFile,
    },

    data() {
        return {
            id: this.$route.params.id,
            item: {},
            availableFiles: [],
            file: null,
        };
    },

    computed: {
        brandSrc() {
            const src = paymentSchemesToImg[this.item.brandDescription] || 'default.svg';
            return require(`@/assets/images/brands/${src}`);
        },
    },

    async created() {
        this.callLoading(true);
        await Promise.all([
            this.getHistory(),
            this.getAvailableFiles(),

        ]);
        this.callLoading(false);
    },

    methods: {
        async getHistory() {
            const { data } = await ChargebackService.history({ chargebackId: this.id });

            this.item = data;
        },

        async getAvailableFiles() {
            const { data } = await ChargebackService.availableFiles({ chargebackId: this.id });

            this.availableFiles = data;
        },

        async uploadFile(file) {
            const isConfirmed = await this.confirmAnAction('Você Deseja Realmente Enviar Esse Arquivo');
            if (!isConfirmed) return;

            this.callLoading(true);
            const { status } = await ChargebackService.upload({ file, chargebackId: this.id });
            this.callLoading(false);

            if (status === 200) {
                this.modalSuccess('Arquivo enviado com sucesso');

                return;
            }

            this.modalError('Erro ao enviar arquivo');
        },

        async downloadFile(fileName) {
            const { data } = await ChargebackService.download({ fileName });

            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
    },
};
</script>

<style lang="scss">
.price {
    font-size: 2rem;
    color: #000;
}

.dark-layout {
    .price {
        color: #fff;
    }
}
</style>
